import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  billPurchaseDefaultData,
  ERRORMESSAGE,
  errorToastOptions,
  statusSelectData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccounts from "../../../hooks/useAccounts";
import useSuppliers from "../../../hooks/useSuppliers";
import {
  formatAcountSelectData,
  formatbidSelectData,
  formatPoSelectData,
  formatRequisitionSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import SentePayLogo from "../../../assets/images/sentepay.svg";
import usePaymentTypes from "../../../hooks/usePaymentTypes";
import useRequisition from "../../../hooks/useRequisition";

interface IAddBillingProps {
  closeModal: () => void;
  refresh: () => void;
}

const AddPurchaseBill = ({ closeModal, refresh }: IAddBillingProps) => {
  const [po, setPo] = useState({});
  const [selectedPo, setSelectedPo] = useState<any>([]);
  const [bill, setBill] = useState(billPurchaseDefaultData);
  const [totalAmount, setTotalAmount] = useState(0);
  const [formLoading, setLoading] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const { paymentTypes } = usePaymentTypes();
  const { formattedSupplier, loading, suppliers } = useSuppliers();
  const { accounts } = useAccounts();
  const validator = new Validator();
  const {  getPurchaseOrder, purchaseOrder } = useRequisition();
  useEffect(() => {
    getPurchaseOrder();
  }, []);

  const handleSelected = (e: any) => {
    const { value, name } = e;
    if (value !== "") {
      if (name === "purchase_order_id") {
        setSelectedPo(purchaseOrder.filter((item: any) => item?.id === value)[0]);
      }

      setPo({
        ...bill,
        ...{
          [name]: { ...bill[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setPo({
        ...bill,
        ...{
          [name]: { ...bill[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setBill({ ...bill, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setBill({ ...bill, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    if (value !== "") {
      if (name === "status_id") {
        if (value === "2") {
          setShowAccounts(true);
        } else {
          setShowAccounts(false);
        }
      }

      // update the state
      setBill({ ...bill, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setBill({ ...bill, ...{ [name]: { error: true, data: value } } });
    }
  };

  useEffect(() => {
    if (selectedPo?.items) {
      let total = 0;
      selectedPo.items.forEach((item: any) => {
        total += Number(item.total);
      });
      setTotalAmount(total);
    }
  }, [selectedPo]);
  console.log(selectedPo);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(bill, setBill)) {
      setLoading(true);
      let credit;
      switch (Number(bill.payment_channel.data)) {
        case 2:
          credit = 9; // get the mobile money account id
          break;
        case 3:
          credit = 8; // get the bank account id
          break;
        default:
          credit = 7; // get the cash account id
          break;
      }

      const data = { ...getFormData(bill), credit: credit, bill_amount:totalAmount, name:"Test User", user_type: 1, supplier_id: selectedPo.supplier_id};

      try {
        const response = await doCreate("bills/create", data);
        if (response.data.success === true) {
          toast.success("New bill saved successfully", successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(
            formatErrorMessage(response.data.data, response.data.message),
            errorToastOptions
          );
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false);
      }
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      {bill.payment_channel.data === "2" && (
        <>
          <div className="form-row align-items-center">
            <div className="col-sm-3">
              <h5 className="text-primary">Powered by: </h5>
            </div>
            <div className="col-sm-9">
              <img
                src={SentePayLogo}
                style={{
                  width: "50%",
                  objectFit: "contain",
                  height: "100%",
                  display: "flex",
                  margin: "0 auto",
                }}
                alt=""
              />
            </div>
          </div>
          <hr />
        </>
      )}
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formatPoSelectData(purchaseOrder,suppliers)}
            id="purchase_order_id"
            label="Purchase Order"
            name="purchase_order_id"
            required
            handleChange={handleSelected}
            errorMessage="Please select the purchase order"
          />
        </div>
        {/* <div className="col-4">
          <InputSelect
            data={formattedSupplier}
            name="supplier_id"
            label="Supplier name"
            id="supplier_id"
            required
            handleChange={handleSelectChange}
            error={bill?.supplier_id.error}
            errorMessage="Please select the supplier name"
            value={bill?.supplier_id.data}
          />
        </div> */}

        <div className="col-sm-6">
          <Input
            type="number"
            name="bill_amount"
            label="Bill Amount"
            handleChange={handleChange}
            value={String(totalAmount)}
            required
            readonly
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-4">
          <InputSelect
            data={statusSelectData}
            name="status_id"
            label="Status"
            id="status_id"
            required
            handleChange={handleSelectChange}
            error={bill?.status_id.error}
            errorMessage="Please select the status id"
            value={bill?.status_id.data}
          />
        </div>
        <div className="col-4">
          <InputSelect
            data={paymentTypes?.map((item: any) => ({
              label: item?.name,
              value: item?.id,
            }))}
            name="payment_channel"
            label="Payment Channel"
            id="payment_channel"
            required
            handleChange={handleSelectChange}
            error={bill?.payment_channel.error}
            errorMessage="Please provide the payment channel"
            value={bill?.payment_channel.data}
          />
        </div>
        {showAccounts && (
        <div className="col-4">
            <InputSelect
              data={formatAcountSelectData(accounts)}
              name="debit"
              label="Expense Account"
              id="debit"
              required={showAccounts}
              handleChange={handleSelectChange}
              error={bill?.debit.error}
              errorMessage="Please select the account to debit"
              value={bill?.debit.data}
            />
          </div>
          )}
      </div>
      {!showAccounts && (
        <div className="form-row">
          <div className="col-6">
            <InputSelect
              data={formatAcountSelectData(accounts)}
              name="debit"
              label="Payable Account"
              id="debit"
              required={!showAccounts}
              handleChange={handleSelectChange}
              error={bill?.debit.error}
              errorMessage="Please select the account to debit"
              value={bill?.debit.data}
            />
          </div>
          <div className="col-6">
            <InputSelect
              data={formatAcountSelectData(accounts)}
              name="debit"
              label="Expense Account"
              id="debit"
              required={!showAccounts}
              handleChange={handleSelectChange}
              error={bill?.debit.error}
              errorMessage="Please select the account to debit"
              value={bill?.debit.data}
            />
          </div>
        </div>
      )}

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddPurchaseBill;
